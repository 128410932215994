const projectData = [
  // {
  //   id: 0,
  //   name: "Adopt Don't Shop",
  //   image:
  //     "",
  //   description:
  //     "",
  //   partners: [
  //     { name: "Demaceo Vincent", link: "https://github.com/demaceo" },
  //   ],
  //   link: "https://demaceo.github.io/adoptdontshop/",
  //   icon: "fas fa-dog icon",
  // },
  {
    id: 1,
    name: "Moody Tunes",
    image:
      "https://user-images.githubusercontent.com/66697338/104397652-796aac00-550a-11eb-8f64-4b7f7e0c07cc.gif",
    description:
      "Moody Tunes is a music recommendation engine that provides music recommendations based on a user's mood. Users are able to save songs into their 'Favorites' and view the information at a later time, or play the song directly from Spotify.",
    partners: [
      { name: "Demaceo Vincent", link: "https://github.com/demaceo" },
      { name: "Scott Brabson", link: "https://github.com/brabbuss" },
      { name: "Bruce Gordon", link: "https://github.com/bruce-gordon" },
    ],
    link: "https://demaceo.github.io/moodytoonz/",
    icon: "fas fa-theater-masks icon",
  },
  {
    id: 2,
    name: "Cappua",
    image: "https://media.giphy.com/media/VTLAVXMEcDEMuGWDV5/giphy.gif",
    description:
      "One part crowdsourced songwriting; one part rap battle. Cappua is a monthly Hip-Hop music competition app in which users compete to get their verse included in the given month's featured track. Users upload their verse for a month's track and vote on their favorite uploaded verses as they vie for their place on the Cappua Hall of Fame, otherwise known as Olympus.",
    partners: [
      { name: "Demaceo Vincent", link: "https://github.com/demaceo" },
      { name: "Cooper Terrones", link: "https://github.com/coopterrones" },
      { name: "Nick King", link: "https://github.com/nmking22" },
      { name: "Eugene Theriault", link: "https://github.com/ETBassist" },
      { name: "Sage Gonzales", link: "https://github.com/SageOfCode" },
      { name: "Brian Liu", link: "https://github.com/the-color-bliu" },
    ],
    link: "https://github.com/Cappua/cappua-fe",
    icon: "fas fa-music icon",
  },
  {
    id: 3,
    name: "What's Cookin",
    image: "https://media.giphy.com/media/yHlPATYrKHmIBcgHpM/giphy.gif",
    description:
      "A simple and elegant site to search, share and store your recipes. This site presents the user with the ability to 'log-in' and search the site for recipes.",
    partners: [
      { name: "Demaceo Vincent", link: "https://github.com/demaceo" },
      { name: "Scott Brabson", link: "https://github.com/brabbuss" },
    ],
    link: "https://demaceo.github.io/whats-cookin/",
    icon: "fas fa-cookie-bite icon",
  },
  {
    id: 4,
    name: "Rancid Tomatillos",
    image: "https://media.giphy.com/media/0aJoS84OErWv5MlCmw/giphy.gif",
    description:
      "The site presents the user an assortment of movies with their corresponding information and gives the user the ability to sort movies based on their ratings.",
    partners: [
      { name: "Demaceo Vincent", link: "https://github.com/demaceo" },
      { name: "Caleb Cyphers", link: "https://github.com/CalebCyphers" },
    ],
    link: "https://github.com/demaceo/rantom",
    icon: "fas fa-film icon",
  },
];

export default projectData
